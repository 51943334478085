/* global style  */
* {
  margin: 0;
  padding: 0;
}

:root {
  --main-color: #7a32ff;
  --secondary-color: #ff498c;
  --light-color: rgba(173, 173, 173, 0.2);
  --bg-color: #fff;
  /* ---- override toast default color ---- */
  --toastify-icon-color-success: var(--main-color) !important;
  --toastify-color-success: var(--main-color) !important;
}

.form-check-input:checked {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.form-control:focus,
.form-check-input:focus {
  border-color: #7a32ff70 !important;
  box-shadow: 0 0 0 0.25rem #7a32ff36 !important;
}

body {
  font-family: Almarai !important;
  overflow-x: hidden;
  direction: rtl;
  text-align: start !important;
}

span {
  text-align: start !important;
}

a {
  color: var(--secondary-color) !important;
  text-decoration: none !important;
}

.page-link {
  color: black !important;
}

/* .css-1fdsijx-ValueContainer {
  direction: ltr !important;
}
.css-13cymwt-control {
  height: 58px !important;
} */

#signinSection {
  overflow-y: hidden !important;
}

main {
  background-color: var(--main-color);
}

input:focus,
button:focus {
  border: 1px solid var(--main-color);
  box-shadow: none;
}

.form-check-input:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
}
/* end general styles */
